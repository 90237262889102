.tarifaContainer {
    width: 100%;
    height: 51%;
    color: #fff !important;
    position: absolute;
    top: 61.4%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    /* font-style: italic; */
    /* font-weight: bold; */
  }
  
  .tarifa {
      position: absolute;
      top: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      /* font-style: italic; */
      /* font-weight: bold; */
    }
    table {
      width: 1080px;
      border-collapse: collapse;
      overflow: hidden;
      box-shadow: 0 0 100px #000B46;
    }
    th,
    td {
      padding: 10px;
      font-family:Arial, Helvetica, sans-serif;
      background-color: rgba(43, 31, 51, 0.603);
      font-size: 24px;
      color: #fff;
    }
    th {
      text-align: center;
    }
    thead th {
      font-size: 35px;
      background-color: rgb(4, 185, 245);
    }
    tbody tr:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    tbody td {
      position: relative;
    }
    tbody td:hover:before {
      position: absolute;
      left: 0;
      right: 0;
      top: -9999px;
      bottom: -9999px;
      background-color: rgba(4, 0, 221, 0.2);
      z-index: -1;
   }
    
   td.valor {
    text-align: right;
    padding-right: 20px;;
  }