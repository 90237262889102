.calendario {
    color: #01177c;
    text-align: center;
    width: 400px;
    padding-left: 15px;
    /* border-left: solid 1px rgba(255,255,255,.4); */
    display: flex;
    flex-direction: column;
    /* align-content: center; */
    justify-content: center;
    height: 10px !important;
    padding-top: -15px;
    text-align: left;
    margin-right: -40px;
}
.fecha {
    padding-top: 18px;
    font-size: 25px;
    text-transform: uppercase;
    padding-left: 1px;
    line-height: 1;
    text-align: left;
    display: block;
    width: 100%;
}
.reloj {
    font-size: 53px;
    font-weight: bolder;
    display: block;
}

/* .calendario {
    color: #fff;
    text-align: left;
    padding-left: 25px;
    border-left: solid 1px rgba(255,255,255,.4);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 200px !important;
    padding-top: 5px;
    
}
.fecha {
    padding-top: 1px;
    font-size:24px;
    text-transform: uppercase;
    padding-left: 0px;
    line-height: 1.2;
    text-align: center;
    display: block;
    width: 100%;
}
.reloj {
    font-size: 75px;
    margin-right: 10px;
    font-weight: bolder;
    display: block;
} */
