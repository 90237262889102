/* https://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	/* orientation: vertical; */
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	/* orientation: vertical; */
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body, html {
	height: 100%;
	width: 100%;
	background-color: #00ff55;
	background-image: url('./assets/images/bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	overflow: hidden;
	font-family: 'Roboto', sans-serif;
}
* {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.container {
	width: auto;
	height: auto;
	margin: 8px auto;
}

.header {
	margin-bottom: 10px;
	margin-top: -10px;
}
.header, .footer {
	display: flex;
	width: 100%;
	height: 100px;
	margin-bottom: -20px;
}

.footer {
	box-sizing: border-box;
	margin-top: -18px;
	height: 230px;
	width: 1920px;
}
.content {
	height: 640px;
	margin-bottom: 20px;
}

.row {
	width: 100%;
}
.flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.flex-end {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

#masInformacion {
	width: 1100px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

#masInformacion2 {
	width: 800px;
	display: flex;
	margin-left: -180px;
	margin-top: -100px;
	
	/* justify-content: flex-end; */
	/* align-items: center; */
	/* height: 44px; */
}

#notification {
	width: 1056px;
	height: 180px;
	border-radius: 6px;
	background-color: orange;
}

#itinerario {
	width: 763px;
	height: 640px;
	align-self: stretch;
}

#multimediaPlayer {
	width: 1140px;
	/* height: 720px; */
	margin-left: 0px;
	/* background-color: ; */
}