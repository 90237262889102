.QRTarifa {
    height: 130px;
    display: block;
    float: left;
    padding: 285px 10px 0px 40px;
    padding-right: 0%;
    z-index: 100;
    margin-top: -18px;
}

.TituloQrTarifa {
    color: white;
    font-family: Arial black;
    font-size: 2.8rem;;
    display: block;
    float: left;
    padding: 310px 0px 0px 70px;
    z-index: 100;
}  