.wrapper {
    margin-top: 0px;
    text-align: center;
    background-color: #00000094;
    /* opacity: 80%; */
    height: 111vw;
    }
    
    .textoTitulo {  
        font-size: 35px;
        color: white;
        font-family: Impact;
    }
    
    .texto {
        font-family: Impact;
        font-size: 24px;
        color: white;
        margin-right: 3% !important ;
    }
    
    .Titulo {
    color: white; 
    text-align: center;
    font-family: Arial black;
    font-size: 1.7rem;
    height: 2vh;
    }  
    
    .card {
    background-color: #001f64;
    float: left;
    border: 1px solid;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);
    border-radius: 1vh;
    margin-left: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 47vw;
    height: 25vh;
    }
    
    .TituloCard{
    background-color: #001f64;
    float: left;
    border: 1px solid;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);
    border-radius: 2vh;
    margin-left: 2%;
    width: 47vw;
    height: 2vh;
    }
      
    .SelectInfo{
    border-radius: 2vh;
    }
    
    .TituloCard1{
    background-color: #001f64;
    float: left;
    border: 1px solid;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);
    border-radius: 2vh;
    margin-left: 6%;
    width: 90vw;
    height: 2vh;
    }
    
    .card1 {
    background-color: #001f64;
    float: left;
    border: 1px solid;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);
    border-radius: 1vh;
    margin-left: 6%;
    margin-top: 5%;
    margin-bottom: 2%;
    width: 90vw;
    height: 20vh;
    }
    