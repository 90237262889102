#footer {
	height: 140px;
    /* background-color:; */
    padding-bottom: 0%;
    z-index: 100;
    position:static;
    left: 0;
    bottom: 40px;
    width: 1080px;
    float: right;
    text-align: center;
    margin-top: 1vw;
    background-color: #ffcc29;
    border-top: #001033 12px solid;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.prueba {
    color: black;
    font-size: 135px;
    opacity: 10%;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 100 !important;
    margin-bottom: 22px;
}