.wrapper {
    margin-top: 0px;
    text-align: center;
    background-color: #00000094;
    height: 60vh;
}

.g_txt{
    font-family:'Times New Roman', Times, serif !important;
    font-size: 35px !important;
    color: white;
}

.u_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-left: 2%;
    width: 96vw;
    height: 2vh;
    text-align: center;
    margin-top: 1vh;
}

.u_c_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1vh !important;
    margin-bottom: 1vh !important;
    margin-left: 2% !important;
    width: 96vw;
    height: 56.4vh;
}

.u_img{
    background-color: white;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1vh !important;
    margin-bottom: 1vh !important;
    margin-left: 2% !important;
    width: 1037px;
    height: 1132px;
}

.d_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-left: 2%;
    width: 47vw;
    height: 2vh;
    text-align: center;
    margin-top: 0.6vh;
}

.d_c_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1vh !important;
    margin-bottom: 1vh !important;
    margin-left: 2% !important;
    width: 47vw;
    height: 56.6vh;
}


.d_img{
    background-color: white;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 0.6vh !important;
    margin-bottom: 1vh !important;
    margin-left: 2% !important;
    width: 47vw;
    height: 59.6vh;
}

.t_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-left: 2%;
    width: 96vw;
    height: 2vh;
    text-align: center;
    margin-top: 1%;
}

.t_c_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    width: 96vw;
    height: 27vh;
}

.t_img{
    background-color: white;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    width: 96vw;
    height: 29.5vh;
}

.c_t_tt{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-left: 2%;
    width: 47vw;
    height: 2vh;
    text-align: center;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
}

.c_t_bb{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-left: 2%;
    width: 47vw;
    height: 2vh;
    text-align: center;
    margin-bottom: 1% !important;
}




.dl{
    float: left;
    width: 47vw !important;
    margin-left: 1% !important;
}

.dl_cu{
    float: left;
    width: 47vw !important;
    margin-left: 1% !important;
    margin-right: 1% !important;
}

.dl_se{
    float: left;
    width: 47vw !important;
    margin-left: 1% !important;
    margin-right: 1% !important;
    margin-top: 0.5% !important;
    margin-bottom: 0.5% !important;
}


.cu_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-left: 2%;
    width: 47vw;
    height: 2vh;
    text-align: center;
    margin-top: 0.6vh;
}

.cu_c_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 2% !important;
    margin-bottom: 1vh !important;
    margin-left: 2% !important;
    width: 47vw;
    height: 57.1vh;
}


.cu_img{
    background-color: white;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 0.6vh !important;
    margin-bottom: 1vh !important;
    margin-left: 2% !important;
    width: 47vw;
    height: 59.6vh;
}


.ci_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-left: 2%;
    width: 96%;
    height: 2vh;
    text-align: center;
    margin-top: 1%;
}

.ci_c_t{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    width: 96%;
    height: 26.8vh;
}

.ci_img{
    /* background-color: white; */
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    width: 96%;
    height: 29.5vh;
}



.dr{
    float: right;
    width: 47vw !important;
    margin-right: 3% !important;
}


.c_c_tl{
    background-color: #001f64;
    float: left !important;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    width: 47vw;
    height: 27vh;
}

.c_c_tr{
    background-color: #001f64;
    float: right;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    /* margin-top: 1% !important; */
    margin-bottom: 1% !important;
    margin-right: 2% !important;
    width: 47vw;
    height: 27vh;
}

.c_c_tr_ci{
    background-color: #001f64;
    float: right !important;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    /* margin-top: 1% !important; */
    margin-bottom: 1% !important;
    margin-right: -2% !important;
    width: 47vw;
    height: 27vh;
}

.c_c_tb{
    background-color: #001f64;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    width: 47vw;
    height: 27vh;
}

.c_img{
    /* background-color: rgb(0, 112, 150); */
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    width: 47vw;
    height: 29.5vh;
}

.c_img_b{
    background-color: white;
    float: left;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    width: 47vw;
    height: 29.5vh;
}





.textoTitulo {  
    font-size: 35px;
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
    text-align: center !important;
}

.texto {
    font-family:'Times New Roman', Times, serif !important;
    font-size: 35px !important;
    color: white;
    margin-right: 3% !important ;
}

.Titulo {
    color: white; 
    text-align: center;
    font-family: Arial black;
    font-size: 35px;
    height: 2vh;
    margin-left: 2wh !important;
    margin-right: 2wh !important;
}  

.card {
    background-color: #001f64;
    float: left;
    border: 1px solid;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    border-radius: 1vh;
    margin-left: 1wh !important;
    margin-top: 1vh !important;
    margin-bottom: 1vh !important;
    width: 47vw;
    height: 25vh;
}

.cardI {
    background-color: #001f64;
    float: left;
    border: 1px solid;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);*/
    border-radius: 1vh;
    margin-left: 1wh !important;
    margin-bottom: 1vh !important;
    padding-left: 1wh !important;
    width: 47vw;
    height: 28vh !important;
    }

.image{
    max-width: 100%;;
    max-height: 100%;;
    
}

.TituloCard{
background-color: #001f64;
float: left;
border: 1px solid;
box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);
border-radius: 2vh;
margin-left: 2%;
width: 47vw;
height: 2vh;
}
  
.SelectInfo{
border-radius: 2vh;
}


.TituloCard1{
background-color: #001f64;
float: left;
border: 1px solid;
box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);
border-radius: 2vh;
margin-left: 6%;
width: 90vw;
height: 2vh;
}

.card1 {
background-color: #001f64;
float: left;
border: 1px solid;
box-shadow: 0 0 100px rgba(0, 0, 0, 0.986);
border-radius: 1vh;
margin-left: 6%;
margin-top: 5%;
margin-bottom: 2%;
width: 90vw;
height: 20vh;
}
