.qr-angostura{
    height: 130px;
    display: block !important;
    float: right;
    padding: 0px 0px 15px 100px;
    z-index: 100;
    margin-top: -9px;
}

.TituloQr {
    color: white;
    font-family: Arial black;
    font-size: 2.8rem;;
    display: block;
    float: left;
    padding: 37px 0px 0px 100px;
    z-index: 100;
}  
 