#clima {
    width: 500px;
    color: #001f64;
    box-sizing: border-box;
    text-align: center;
    height: 350px;
    display: flex;
    margin-top: 30px;
    margin-left: 0vw;
    justify-content: center !important;
    align-items: center;
}
.climaContainer {
    width: 101%;
    height: 200px;
    padding-top: 90px;
    justify-content: center !important;
    align-items: center !important;
    /* margin-right: 3% !important; */
    
}

.ciudad {
    display: block;
    height: 100px;
    width: 100vw;
}

.icono {
    font-size: 90px !important;
    line-height: 0.5;
    margin-left: 10px;
    display:block
}
.ciudadClima {
    display: flex;
    align-items: center;
}
.ciudadClimaIcono {
    text-align: center;
    align-self: center;
}
.ciudadClimaTemperatura {
    box-sizing: border-box;
    padding-left: 10px;

}
.ciudadTemperatura {
    font-size: 3em;
    display: block;
    font-weight: bolder;
}
.ciudadDetalle {
    display: block;
    width: 100%;
    font-size: 18px;
}
.ciudadDetalle h3, .ciudadDetalle h2 {
    line-height: 1em;
    margin: 0px;
    padding: 0px;
}
.ciudadDetalle h3 {
    font-weight: normal;
    font-size:0.9em;
    text-transform: lowercase
}

.ciudadDetalle h2 {
    font-weight: bold;
    font-size: 1.2em;
}

@font-face {
    font-family: OpenWeatherIcons;
    src: url("./fonts/OpenWeatherIcons.eot");
    src: url("./fonts/OpenWeatherIcons.eot?#iefix") format("embedded-opentype"), url("./fonts/OpenWeatherIcons.woff2") format("woff2"), url("./fonts/OpenWeatherIcons.woff") format("woff"), url("./fonts/OpenWeatherIcons.ttf") format("truetype"), url("./fonts/OpenWeatherIcons.svg#OpenWeatherIcons") format("svg");
    font-style: normal;
    font-weight: 400;
}

.owi {
    display: inline-block;
    transform: translate(0, 0);
    text-rendering: auto;
    font: normal normal 400 14px/1 OpenWeatherIcons;
    font-size: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.owi-lg {
    vertical-align: -15%;
    line-height: 0.75em;
    font-size: 1.33333333em;
}

.owi-2x {
    font-size: 2em;
}

.owi-3x {
    font-size: 3em;
}

.owi-4x {
    font-size: 4em;
}

.owi-5x {
    font-size: 5em;
}

.owi-fw {
    width: 1.28571429em;
    text-align: center;
}

.owi-01d::before {
    content: "\ea01";
}

.owi-01n::before {
    content: "\ea02";
}

.owi-02d::before {
    content: "\ea04";
}

.owi-02n::before {
    content: "\ea03";
}

.owi-03d::before {
    content: "\ea05";
}

.owi-03n::before {
    content: "\ea06";
}

.owi-04d::before {
    content: "\ea07";
}

.owi-04n::before {
    content: "\ea08";
}

.owi-09d::before {
    content: "\ea09";
}

.owi-09n::before {
    content: "\ea0a";
}

.owi-10d::before {
    content: "\ea0b";
}

.owi-10n::before {
    content: "\ea0c";
}

.owi-11d::before {
    content: "\ea0d";
}

.owi-11n::before {
    content: "\ea0e";
}

.owi-1232n::before {
    content: "\ea0f";
}

.owi-13d::before {
    content: "\ea10";
}

.owi-13n::before {
    content: "\ea12";
}

.owi-50d::before {
    content: "\ea11";
}

.owi-50n::before {
    content: "\ea13";
}